body {
	display:none;
}

textarea {
    resize: vertical;
    min-height: 40px;
}


$gray-base:              #000 !default;

.gray-light {
	color: lighten($gray-base, 85%);
}
.gray {
	color: lighten($gray-base, 75%);
}
.gray-dark {
	background-color: lighten($gray-base, 65%);
}


.bg-gray-light {
	background-color: lighten($gray-base, 95%);
}
.bg-gray {
	background-color: lighten($gray-base, 85%);
}
.bg-gray-dark {
	background-color: lighten($gray-base, 75%);
}



#ajaxState {
	background-color:rgba(214, 0, 1, 0.8);
	color:#fff;
	font-weight:bold;
	font-size:13px;
	padding:3px 45px;
	padding-left:15px;
	position:fixed;
	top:-40px;
	left:0px;
	z-index:1042;
	display:block;
	-webkit-transition: top 0.3s ease;
	-moz-transition: top 0.3s ease;
	-o-transition: top 0.3s ease;
	-ms-transition: top 0.3s ease;
	transition: top 0.3s ease;


	-webkit-border-radius: 0px 0px 50px 0px;
	-moz-border-radius: 0px 0px 50px 0px;
	border-radius: 0px 0px 50px 0px;
	
	-webkit-box-shadow: #555 4px 4px 4px;
	-moz-box-shadow: #555 4px 4px 4px;
	box-shadow: #555 4px 4px 4px;	
}
#ajaxState.show {
	top:0;
}


.table-list {
	tr th {
		width:50%;
		text-align: right;
	}	
}

.table {
    &.tr-cursor {
        tbody tr:hover {
            cursor:pointer;
        }
    }
}

.form-control.half-field {
	width: 50%;
}
.form-control.quater-field {
	width: 25%;
}

.ajax-loader-main {
	position:absolute;
	top:16px;
	left:27px;
	width:16px;
	height:16px;
	z-index:1001;
	display:block;	
}
.ready .ajax-loader-main {
	display:none;
}


.btn:focus {
  outline: none;
}


.without_teams {
	border:2px dashed #444;
	opacity:0.8;
	background-color:#EFA9A0;
}

.current-day {
	th {
		color:red;
	}
}


.blink_me {
    -webkit-animation-name: blinker;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;

    -moz-animation-name: blinker;
    -moz-animation-duration: 1s;
    -moz-animation-timing-function: linear;
    -moz-animation-iteration-count: infinite;

    animation-name: blinker;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
@-moz-keyframes blinker {  
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
}
@-webkit-keyframes blinker {  
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
}
@keyframes blinker {  
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
}



.checkbox.disabled {
    label {
        color: lighten($gray-base, 75%);
    }

    /*input[type=checkbox][disabled]{
        outline:1px solid red; // or whatever
    }*/
}




#wrapper {
    padding-right: 0;
    transition: all 0.5s ease;

    &.toggled {
        padding-right: 250px;
        
        #sidebar-wrapper {
            width: 250px;
        }
        #page-content-wrapper {
            position: absolute;
            margin-right: -250px;
        }
    }
}

#sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    right: 250px;
    top:83px;
    width: 0;
    height: 100%;
    margin-right: -250px;
    overflow-y: auto;
    background: #000;
    transition: all 0.5s ease;
	box-shadow: -5px 5px 10px 0 #444;
}


#page-content-wrapper {
    width: 100%;
    position: absolute;
    padding: 15px;
}


/* Sidebar Styles */

.sidebar-nav {
    position: absolute;
    top: 0;
    bottom:76px;
    width: 250px;
    margin: 0;
    padding: 10px;
    list-style: none;

    li {
        
        line-height: 40px;
        padding-top:5px;
        padding-bottom:5px;

        *:not(.btn, select, option) {
            color: #eee;
        }

        select, option {
            color: #000;
        }

        

        /*a {
            //display: block;
            text-decoration: none;
            //color: #aaa;

            &:hover {
                text-decoration: none;
                color: #fff;
                background: rgba(255,255,255,0.2);
            }
            &:active,
            &:focus {
                text-decoration: none;
            }
        }*/

        #sidebarTeamDetails {
            .media {
                cursor:pointer;
                padding:4px;
                margin-top: 0;

                .media-heading {
                    font-size: 15px;
                }
                &:hover {
                    background-color:rgba(255,255,255,0.2);
                    .media-heading {
                        text-decoration:underline;
                    }
                }
                &.removed {
                    opacity:0.3;
                }
            }
        }

        #workInfo {
            p {
                line-height: 16px;
                margin-top:5px;
                margin-bottom:5px;
            }
            .heading {
                margin-top:0;
            }
        }

        #sidebarAction {
            padding:5px;
            border-top:1px solid #eee;
            border-bottom:1px solid #eee;
        }
    }

    /*> .sidebar-brand {
        height: 65px;
        font-size: 18px;
        line-height: 60px;

        a {           
            &:hover {
                text-decoration:underline;
                background: none;
            }
        }
    }*/
}


@media(min-width:768px) {
    #wrapper {
        padding-right: 250px;
    }

    #wrapper.toggled {
        padding-right: 0;
    }

    #sidebar-wrapper {
        width: 250px;
    }

    #wrapper.toggled #sidebar-wrapper {
        width: 0;
    }

    #page-content-wrapper {
        padding: 20px;
        position: relative;
    }

    #wrapper.toggled #page-content-wrapper {
        position: relative;
        margin-right: 0;
    }
}

#menu-toggle {
	position:fixed;
	right:0;
	top:54px;
	z-index: 999;
	/*background: #000;
	border:0;
	color: #fff;*/
}




/*.planning-scroll {
    .mCSB_scrollTools {
        position:fixed !important;
    }
}*/

#planningTable {
    display: block;

    tbody {
        tr {
            margin: 0;

            th.th-day-col {
                padding:2px;
                text-align: center;
                line-height: 13px;
                font-size:13px;
                small {
                    color: #999;
                    font-weight: normal;
                    font-size: 11px;
                }
            }
        
        
            td {
                max-width:500px;
                min-width:400px;
                cursor:pointer;
                padding: 1px;

                .well-sm {
                    padding:2px;
                    margin-bottom: 1px;
                    margin-right: 15px;
                }

                &.active,
                &.active:hover{
                    background-color:rgba(155,0,0,0.2);
                }
                
                &:hover {
                    background-color:#efefef;
                }
            }
        }
    }
}




.photo-xs {
    max-width: 50px;
    max-height: 100px;
}


.colorpicker {
    z-index: 999 !important;
    &:before {
        display: none !important;   // ukryie strzałki nad input
    }
}


/* wyświetlanie wartości procentowych na bootstrapowym progress bar - wartości procentowe są wyświetlane na środku paska*/
.progress.my-progress {
    position: relative;

    .progress-bar span{
        color:#333;
        position:absolute;
        left:0;
        right:0;
        font-weight: bold;
    }
}

/*.tooltip-inner {
    white-space:pre-wrap;
}*/

@import "../bootstrap-modified/variables";
//@import "../bootstrap/mixins";


.text-error {
	//@extend .text-danger;
	color: $state-danger-text;
}
.bg-error {
	//@extend .bg-danger;
	background-color: $state-danger-text;
}



.flag {
    display:inline-block;
    width: 25px;
    height: 15px;
    background:url(../img/flags.png) no-repeat;
    border:1px solid #CCC;
}

.flag.flag-at {background-position: -25px 0}
.flag.flag-cz {background-position: -50px 0}
.flag.flag-de {background-position: -75px 0}
.flag.flag-es {background-position: 0 -15px}
.flag.flag-fr {background-position: -25px -15px}
.flag.flag-en {background-position: -50px -15px}
.flag.flag-it {background-position: -75px -15px}
.flag.flag-no {background-position: 0 -30px}
.flag.flag-pl {background-position: -25px -30px}
.flag.flag-pt {background-position: -50px -30px}
.flag.flag-se {background-position: -75px -30px}